import React from "react";
import {NormalLayout} from "../../../components/layouts/normal_layout";
import {Comparison} from "../../../components/products/hyperion/hyperion_s/comparison";
import {Introduction} from "../../../components/products/hyperion/hyperion_s/introduction";
import {Benefits} from "../../../components/products/hyperion/hyperion_s/benefits";
import {Applications} from "../../../components/products/hyperion/hyperion_s/applications";
import {SEO} from "../../../components/seo";

const HyperionS: React.FC = () => {

  return (
    <NormalLayout type="product">
      <SEO title="Hyperion Pro | UV Tech by Rendev" description="Hyperion Pro | UV Tech by Rendev" />
      <Introduction />
      <Benefits />
      <Applications />
      <Comparison />
    </NormalLayout>
  )
}

export default HyperionS;