import React from "react";
import {useTranslation} from "react-i18next";
import {Section} from "../../../common/section";
import {Badges} from "../../../common/badges";
import {graphql, useStaticQuery} from "gatsby";
import GatsbyImage from "gatsby-image";

export const Benefits: React.FC = () => {
  const {t} = useTranslation();

  const items = [1, 2, 3, 4];

  const data = useStaticQuery(graphql`
  query {
  benefits: file(relativePath: {eq: "hyperion/Hyperion-S-normal.jpg"}) { ...squareImage } 
  }`)

  return (
    <Section className="benefits">

      <div className="intro-text">

        <div className="heading-badges">
          <h2>{t('products.hyperion.hyperion_s.benefits.heading')}</h2>
          <Badges/>
        </div>

        <div className="text">
          <p>{t('products.hyperion.hyperion_s.benefits.description')}</p>


        </div>
      </div>
      <div className="advantages">

        <div className="image">
          <GatsbyImage
            style={{height: "100%", width: "100%"}}
            fluid={data.benefits.childImageSharp.fluid}
          />
        </div>

        <div className="text">

          <h2>{t('products.hyperion.hyperion_s.benefits.subsection.heading')}</h2>
          <p>{t('products.hyperion.hyperion_s.benefits.subsection.description')}</p>
          <ul>
            {
              items.map((item, index) => {
                return (
                  <li key={index} className={t(`products.hyperion.hyperion_s.benefits.items.${index}.class`)}>
                    <h4>{t(`products.hyperion.hyperion_s.benefits.items.${index}.heading`)}</h4>
                    <p className="small">{t(`products.hyperion.hyperion_s.benefits.items.${index}.description`)}</p>
                  </li>
                )
              })
            }
          </ul>
        </div>
      </div>
    </Section>
  )
}
